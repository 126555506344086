<script>
import appConfig from "@/app.config";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {},
  data() {
    return {
      salary_items: [],
      SalaryItems: {},
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchSalaryItems: null,
      SearchMode: false,
      emps: [],
      data: {},
      date: 0,
      from: 0,
      to: 0,
      fromCheck: false,
      searchEmployee: null,
      employees: [],
      // appprices:[]
    };
  },
  computed: {
    filteredEmployees() {
      return this.employees
        .filter((employee) => {
          if (employee.name.includes(this.searchEmployee.toLowerCase())) {
            return employee.name;
          } else if (
            employee.code.includes(this.searchEmployee.toLowerCase())
          ) {
            return employee.name;
          }
        })
        .map(
          (employee) =>
            employee.id + "-" + "(" + employee.code + ")" + "-" + employee.name
        );
    },
  },
  methods: {
    searchEmployees() {
      console.log(this.searchEmployee);
      this.http
        .post("emps/auto", {
          search: this.searchEmployee,
        })
        .then((res) => {
          this.employees = res.data;
        });
    },
    attendsReport() {
      console.log(this.searchEmployee);
      if (this.fromCheck == true) {
        this.http
          .post("emps/report", {
            emp_id: this.searchEmployee,
            from: this.from,
            to: this.to,
            limit: this.limit,
            page: this.page,
          })
          .then((res) => {
            this.emps = res.data;
            console.log(this.emps);
            this.searchEmployee = "";
            (this.from = ""), (this.to = "");
          });
      } else {
        this.http
          .post("emps/report", {
            emp_id: this.searchEmployee,
            date: this.date,
          })
          .then((res) => {
            this.emps = res.data;
            console.log(this.emps);
            this.data = {};
          });
      }
    },
    enableFromTo() {
      console.log(this.fromCheck);
      if (this.fromCheck == true) {
        console.log("from");
        document
          .getElementById("oneDateAttends")
          .setAttribute("disabled", "disabled");
        document.getElementById("fromAttends").removeAttribute("disabled");
        document.getElementById("toAttends").removeAttribute("disabled");
        delete this.data["date"];
        console.log(this.data);
      }
      if (this.fromCheck == false) {
        console.log("one");
        document.getElementById("oneDateAttends").removeAttribute("disabled");
        document
          .getElementById("fromAttends")
          .setAttribute("disabled", "disabled");
        document
          .getElementById("toAttends")
          .setAttribute("disabled", "disabled");
        delete this.data["from"];
        delete this.data["to"];
        console.log(this.data);
      }
    },
  },
  created() {},
};
</script>

<template>
  <form
    class="row gx-3 gy-2 align-items-center mb-4"
    @submit.prevent="attendsReport()"
  >
    <div class="col">
      <label class="inline" for="specificSizeInputName">{{
        $t("report.select-employee")
      }}</label>
      <AutoComplete
        @keyup="searchEmployees()"
        v-model="searchEmployee"
        :suggestions="filteredEmployees"
        @complete="search = $event.query"
        style="color: #333 !important; border-radius: 10px !important"
      />
    </div>
    <div class="col">
      <label class="inline" for="oneDateAttends">{{ $t("report.date") }}</label>
      <input
        v-model="date"
        type="date"
        class="form-control oneDate"
        id="oneDateAttends"
      />
    </div>
    <div class="col">
      <div
        class="form-check"
        style="display: flex; justify-content: center; top: 15px"
      >
        <input
          v-model="fromCheck"
          @change="enableFromTo()"
          class="form-check-input"
          type="checkbox"
          value=""
          id="flexCheckDefault"
        />
        <label class="form-check-label" for="flexCheckDefault">
          {{ $t("report.from-to") }}
        </label>
      </div>
    </div>
    <div class="col">
      <label class="inline" for="fromAttends">{{ $t("report.from") }}</label>
      <input
        disabled
        v-model="from"
        type="date"
        class="form-control"
        id="fromAttends"
      />
    </div>
    <div class="col">
      <label class="inline" for="toAttends">{{ $t("report.to") }}</label>
      <input
        disabled
        v-model="to"
        type="date"
        class="form-control fromto"
        id="toAttends"
      />
    </div>
    <div class="col-auto" style="top: 14px">
      <button type="submit" class="btn btn-primary">
        {{ $t("popups.search") }}
      </button>
    </div>
  </form>

  <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
    <thead>
      <tr
        class="text-light text-center"
        style="background-color: #2a3042 !important"
      >
        <th scope="col">{{ $t("emps.id") }}</th>
        <th scope="col">{{ $t("emps.code") }}</th>
        <th scope="col">{{ $t("emps.name") }}</th>
        <th scope="col">{{ $t("emps.check-in") }}</th>
        <th scope="col">{{ $t("emps.check-out") }}</th>
        <th scope="col">{{ $t("emps.date") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr class="text-center" v-for="(emp, index) in emps" :key="emp">
        <td scope="col">{{ index + 1 }}</td>
        <td scope="col">{{ emp?.code }}</td>
        <td scope="col">{{ emp?.name }}</td>
        <td scope="col">
          {{ emp?.emp_attends[0]?.check_in }}
        </td>
        <td scope="col">
          {{ emp?.emp_attends[0]?.cheak_out }}
        </td>
        <td scope="col">{{ emp?.created.split("T")[0] }}</td>
      </tr>
    </tbody>
  </table>

  <!--   Apps  Pagination     -->
  <ul
    v-if="tot_pages >= 20"
    class="pagination pagination-rounded justify-content-center mb-2"
  >
    <li class="page-item" :class="{ disabled: page == 1 }">
      <a
        class="page-link"
        href="javascript: void(0);"
        @click="get(page - 1)"
        aria-label="Previous"
      >
        <i class="mdi mdi-chevron-left"></i>
      </a>
    </li>
    <li
      class="page-item"
      :class="{ active: p == page }"
      v-for="p in tot_pages"
      :key="p"
    >
      <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
        p
      }}</a>
    </li>

    <li class="page-item" :class="{ disabled: page == total_pages }">
      <a
        class="page-link"
        href="javascript: void(0);"
        @click="get(page + 1)"
        aria-label="Next"
      >
        <i class="mdi mdi-chevron-right"></i>
      </a>
    </li>
  </ul>
  <!--end-->
</template>
