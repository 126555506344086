<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import EmployeebyBranch from "./Employee by branch/EmployeebyBranch.vue";
import EmployeebyDegree from "./Employee by Degree/EmployeebyDegree.vue";
import EmployeebyJobtitle from "./Employee by jobtitle/EmployeebyJobtitle.vue";
import EmployeeUsers from "./Employee users/EmployeeUsers.vue";
import EmployeebyAttends from "./Employee by Attends/EmployeebyAttends.vue";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    EmployeebyBranch,
    EmployeebyDegree,
    EmployeebyJobtitle,
    EmployeeUsers,
    EmployeebyAttends,
  },
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('report.employees-report')"
    />
    <b-tabs
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      justified
      nav-class="nav-tabs-custom"
      content-class="p-3 text-muted"
    >
      <b-tab active>
        <template v-slot:title>
          <span
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            class="d-inline-block d-sm-none"
          >
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{
            $t("report.employee-by-branch")
          }}</span>
        </template>
        <employeeby-branch></employeeby-branch>
        <!-- end emp tab-->
      </b-tab>
      <b-tab>
        <template v-slot:title>
          <span
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            class="d-inline-block d-sm-none"
          >
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block"
            >{{ $t("report.employee-by-degree") }}
          </span>
        </template>
        <employeeby-degree></employeeby-degree>
      </b-tab>

      <b-tab>
        <template v-slot:title>
          <span
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            class="d-inline-block d-sm-none"
          >
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block"
            >{{ $t("report.employee-by-jobtitle") }}
          </span>
        </template>
        <employeeby-jobtitle></employeeby-jobtitle>
      </b-tab>

      <b-tab>
        <template v-slot:title>
          <span
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            class="d-inline-block d-sm-none"
          >
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block"
            >{{ $t("report.employee-users") }}
          </span>
        </template>
        <employee-users></employee-users>
      </b-tab>
      <b-tab>
        <template v-slot:title>
          <span
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            class="d-inline-block d-sm-none"
          >
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block"
            >{{ $t("report.employee-by-attends") }}
          </span>
        </template>
        <employeeby-attends></employeeby-attends>
      </b-tab>
    </b-tabs>
  </Layout>
</template>
<style scoped>
</style>




