<script>
import appConfig from "@/app.config";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
  
  },
  data() {
    return {
      salary_items: [],
      SalaryItems: {},
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchSalaryItems: null,
      SearchMode: false,
      emps:[],
      branches:[],
      data:{},
      fromCheckJobTitle:false,
      jobs:[]
      // appprices:[]
    };
  },
  methods: {
   jobTitleReport(){
    this.http.post("emps/report",this.data).then(res=>{
      this.emps = res.data
      this.data = {};
    })
   },
    getBranch(){
        this.http.get("branches",{
            limit: this.limit,
            page: this.page,
        }).then(res=>{
            this.branches = res.data
        })
    },
    enableFromTo(){
      console.log(this.fromCheck);
      if(this.fromCheckJobTitle == true){
        console.log("from");
        document.getElementById('oneDateJobTitle').setAttribute('disabled','disabled')
        document.getElementById('fromJobTitle').removeAttribute('disabled');
        document.getElementById('toJobTitle').removeAttribute('disabled');
        delete this.data['date']
      }
      if(this.fromCheckJobTitle == false){
        console.log("one");
        document.getElementById('oneDateJobTitle').removeAttribute('disabled');
        document.getElementById('fromJobTitle').setAttribute('disabled','disabled')
        document.getElementById('toJobTitle').setAttribute('disabled','disabled')
        delete this.data['from']
        delete this.data['to']
      }
    },
    getJobTitle(){
        this.http.get("emp-job-titles").then(res=>{
            this.jobs = res.data
        })
    }
  },
  created() {
    this.getBranch();
    this.getJobTitle()
  },
};
</script>

<template>
 
    <form class="row gx-3 gy-2 align-items-center mb-4" @submit.prevent="jobTitleReport()">
        <div class="col">
          <label class="inline" for="specificSizeInputName">{{$t("report.select-branch")}}</label>
          <select required v-model="data.branch_id" class="form-select">
            <option value="*">All</option>
            <option v-for="branch in branches" :key="branch" :value="branch.id">{{branch.name}}</option>
          </select>
        </div>

        <div class="col">
            <label class="inline" for="specificSizeInputName">{{$t("report.select-job-title")}}</label>
            <select required v-model="data.emp_job_title_id" class="form-select">
              <option v-for="job in jobs" :key="job" :value="job.id">{{job.name}}</option>
            </select>
          </div>
       
        <div class="col">
            <label class="inline" for="oneDateJobTitle">{{$t("report.date")}}</label>
            <input  v-model="data.date" type="date" class="form-control oneDate" id="oneDateJobTitle">
        </div>
        <div class="col">
            <div class="form-check" style="display:flex;justify-content:center;top: 15px">
                <input v-model="fromCheckJobTitle" @change="enableFromTo()" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault">
                  {{ $t("report.from-to") }}
                </label>
              </div>
        </div>
        <div class="col">
            <label class="inline" for="fromJobTitle">{{ $t("report.from") }}</label>
            <input disabled v-model="data.from" type="date" class="form-control" id="fromJobTitle">
        </div>
         <div class="col">
            <label class="inline" for="toJobTitle">{{ $t("report.to") }}</label>
            <input disabled v-model="data.to" type="date" class="form-control fromto" id="toJobTitle">
        </div>
        <div class="col-auto" style="top:14px">
          <button type="submit" class="btn btn-primary">{{ $t("popups.search") }}</button>
        </div>
      </form>
   

      <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
        <thead>
          <tr class=" text-light text-center" style="background-color: #2a3042 !important;">
            <th scope='col'>{{ $t("emps.id") }}</th>
            <th scope='col'>{{ $t("emps.empjobtitle") }}</th>
            <th scope='col'>{{ $t("emps.branch") }} </th>
            <th scope='col'>{{ $t("emps.code") }}</th>
            <th scope='col'>{{ $t("emps.name") }}</th>
            <th scope='col'>{{ $t("emps.tel") }}</th>
            <th scope='col'> {{ $t("emps.degree") }} </th>
            <th scope='col'>{{ $t("emps.created") }}</th>
            <th scope='col'>{{ $t("emps.updated") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(app, index) in emps" :key="app" class="text-center">
            <td>{{ index + 1 }}</td>
            <td>{{ app.emp_job_title?.name}}</td>
            <td>{{ app.branch?.name }}</td>
            <td>{{ app.code}}</td>
            <td>{{ app.name}}</td>
            <td>{{ app.tel}}</td>
            <td>{{ app.degree }}</td>
            <td>{{ app?.created.split('T')[0] }}</td>
            <td>{{ app.updated.split('T')[0] }}</td>
          </tr>
        </tbody>
      </table>
   

   
   
    <!--   Apps  Pagination     -->
    <ul v-if="tot_pages >=20" class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == total_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
    <!--end-->

</template>