<script>
import appConfig from "@/app.config";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
  
  },
  data() {
    return {
      salary_items: [],
      SalaryItems: {},
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchSalaryItems: null,
      SearchMode: false,
      emps:[],
      branches:[],
      data:{},
      fromCheckUser:false,
      employees:[]
      // appprices:[]
    };
  },
  methods: {
   employeeUser(){
    this.http.post("emps/report",this.data).then(res=>{
      this.emps = res.data
      this.data = {};
    })
   },
    getBranch(){
        this.http.get("branches",{
            limit: this.limit,
            page: this.page,
        }).then(res=>{
            this.branches = res.data
        })
    },
    enableFromTo(){
      console.log(this.fromCheckUser);
      if(this.fromCheckUser == true){
        console.log("from");
        document.getElementById('oneDateUser').setAttribute('disabled','disabled')
        document.getElementById('fromUser').removeAttribute('disabled');
        document.getElementById('toUser').removeAttribute('disabled');
        delete this.data['date']
      }
      if(this.fromCheckUser == false){
        console.log("one");
        document.getElementById('oneDateUser').removeAttribute('disabled');
        document.getElementById('fromUser').setAttribute('disabled','disabled')
        document.getElementById('toUser').setAttribute('disabled','disabled')
        delete this.data['from']
        delete this.data['to']
      }
    },
    getEmp(){
        this.http.get("emps").then(res=>{
            this.employees = res.data
        })
    }
  },
  created() {
    this.getEmp()
  },
};
</script>

<template>
 
    <form class="row gx-3 gy-2 align-items-center mb-4" @submit.prevent="employeeUser()">
        <div class="col">
          <label class="inline" for="specificSizeInputName">{{$t("report.select-employee")}}</label>
          <select required v-model="data.emp_id" class="form-select">
            <option v-for="emp in employees" :key="emp" :value="emp.id">{{emp.name}}</option>
          </select>
        </div>
       
        <div class="col">
            <label class="inline" for="oneDateUser">{{$t("report.date")}}</label>
            <input v-model="data.date" type="date" class="form-control oneDate" id="oneDateUser">
        </div>
        <div class="col">
            <div class="form-check" style="display:flex;justify-content:center;top: 15px">
                <input v-model="fromCheckUser" @change="enableFromTo()" class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault">
                  {{ $t("report.from-to") }}
                </label>
              </div>
        </div>
        <div class="col">
            <label class="inline" for="fromUser">{{ $t("report.from") }}</label>
            <input disabled v-model="data.from" type="date" class="form-control" id="fromUser">
        </div>
         <div class="col">
            <label class="inline" for="toUser">{{ $t("report.to") }}</label>
            <input disabled v-model="data.to" type="date" class="form-control fromto" id="toUser">
        </div>
        <div class="col-auto" style="top:14px">
          <button type="submit" class="btn btn-primary">{{ $t("popups.search") }}</button>
        </div>
      </form>
   

      <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
        <thead>
          <tr class=" text-light text-center" style="background-color: #2a3042 !important;">
            <th scope='col'>{{ $t("emps.id") }}</th>
            <th scope='col'>{{ $t("emps.empjobtitle") }}</th>
            <th scope='col'>{{ $t("emps.branch") }} </th>
            <th scope='col'>{{ $t("emps.code") }}</th>
            <th scope='col'>{{ $t("emps.name") }}</th>
            <th scope='col'>{{ $t("emps.tel") }}</th>
            <th scope='col'> {{ $t("emps.degree") }} </th>
            <th scope='col'>{{ $t("emps.created") }}</th>
            <th scope='col'>{{ $t("emps.updated") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(app, index) in emps" :key="app" class="text-center">
            <td>{{ index + 1 }}</td>
            <td>{{ app.emp_job_title?.name}}</td>
            <td>{{ app.branch?.name }}</td>
            <td>{{ app.code}}</td>
            <td>{{ app.name}}</td>
            <td>{{ app.tel}}</td>
            <td>{{ app.degree }}</td>
            <td>{{ app?.created.split('T')[0] }}</td>
            <td>{{ app.updated.split('T')[0] }}</td>
          </tr>
        </tbody>
      </table>
   

   
   
    <!--   Apps  Pagination     -->
    <ul v-if="tot_pages >=20" class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == total_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
    <!--end-->

</template>